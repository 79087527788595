import { useEffect, useState, useCallback } from 'react';

import './App.css';

import { useDropzone } from "react-dropzone";

const {MerkleTree} = require('merkletreejs');
const keccak256 = require('keccak256');
window.Buffer = window.Buffer || require('buffer').Buffer;

let listTree;

function App() {

  const [proofAddress, setProofAddress] = useState('');

  const [proof, setProof] = useState([]);
  const [root, setRoot] = useState([]);
  const [jsonData, setJsonData] = useState(null);

  const FileDropZone = () => {
 
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
  
        reader.onload = () => {
          const fileAsBinaryString = reader.result;
          const parsedJson = JSON.parse(fileAsBinaryString);
          setJsonData(parsedJson);
        };
        reader.readAsBinaryString(file);
      });
    }, []);
  
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
  
    return (
      <div {...getRootProps({className: 'zone'})}>
        <input {...getInputProps()} />
        <p><small>Glisser/déposer un JSON ici (ou cliquer pour en sélectionner un)</small></p>
      </div>
    );
  };

  const rootGenerator = async () => {

    if (jsonData != null) {
      let listArray = []; 

      jsonData.map(function(entry) { if (entry.address) { listArray.push(entry.address) } else {listArray.push(entry) } });
      const listLeaves = listArray.map(address => keccak256(address));
      listTree = new MerkleTree(listLeaves, keccak256, {sortPairs: true});
      setRoot('0x' + listTree.getRoot().toString('hex'));
      setProofAddress('');
    }
  }

  const proofGenerator = async () => {

    if (jsonData != null) {
      let listArray = []; 

      jsonData.map(function(entry) { if (entry.address) { listArray.push(entry.address) } else {listArray.push(entry) } });
      const listLeaves = listArray.map(address => keccak256(address));
      listTree = new MerkleTree(listLeaves, keccak256, {sortPairs: true});

      setProof(listTree.getHexProof(keccak256(proofAddress)));
    }

  }

  useEffect(() => { rootGenerator(); }, [jsonData])
  useEffect(() => { proofGenerator(); }, [proofAddress])

  return (
    <div className="App">
      <FileDropZone />

      {jsonData ? (<>

      <div className="zone">
        Root<br />
        <div className="root">{root}</div>
      </div>

      <div className="zone">
        Proof Generator<br />
        <input type="text" placeholder="Saisir une adresse" value={proofAddress} onChange={e => setProofAddress(e.target.value)} />
        { (proof != '') ? (<div className="proof">[{proof.map((value, index) => (<span key={index}>"{value}"{index !== proof.length - 1 && ","}</span>))}]</div>) : null }
      </div>
      
      </>) : null }

      <span className="made-with-love">Made with <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank">RpGmAx</a></span>
      
    </div>
  );
}

export default App;
